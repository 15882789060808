@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink {
  50% { border-color: transparent; }
}

.code-background {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  color: lightgray;
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  white-space: pre;
  z-index: -1;
  padding: 20px;
}

.code-line {
  display: inline-block;
  overflow: hidden;
  border-right: 2px solid lightgray;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 4s steps(40, end) infinite, blink .75s step-end infinite;
}
